<template>
  <div>
    <van-nav-bar
      :title="data.title"
      left-text="返回"
      left-arrow
      :class="[$store.state.style]"
      @click-left="onClickLeft"
    />
    <div :class="['main', $store.state.style]" ref="main">
      <iframe
        height="100%"
        width="100%"
        ref="iframe"
        scrolling="no"
        :src="data.contentPath"
        frameborder="0"
      ></iframe>
      <Card
        v-for="item in list"
        :key="item.index"
        :data="item"
        :class="['card', $store.state.style]"
        @click.native="submit(item)"
      />
    </div>
  </div>
</template>

<script>
import { NavBar, Toast } from 'vant'
import { getNewsDetail, getNewsList } from '@/api'
import Card from '@/components/Card'
export default {
  components: {
    [NavBar.name]: NavBar,
    Card
  },
  data() {
    return {
      data: '',
      list: []
    }
  },
  created() {
    this.init()
    this.newsList()
    window.addEventListener('message', this.handleFrameMessage)
  },
  methods: {
    init() {
      getNewsDetail({ newsId: this.$route.params.id }).then(res => {
        if (res.code === 200) {
          this.data = res.data
        } else {
          Toast.fail(res.message)
        }
      })
    },
    newsList() {
      const params = {
        catId: 1,
        page: 1,
        size: 20
      }
      getNewsList(params).then(res => {
        if (res.code === 200) {
          this.list = res.data.listInfo
        } else {
          Toast.fail(res.message)
        }
      })
    },
    handleFrameMessage(event) {
      const msgData = event.data
      switch (msgData.cmd) {
        case 'returnHeight':
          if (this.$refs.iframe) {
            this.$refs.iframe.height = msgData.params.height
          }
          break
      }
    },
    // 跟新浏览内容
    submit(val) {
      this.$refs.main.scrollTop = 0
      this.data = val
    },
    // 返回首页
    onClickLeft() {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar.styleB {
  background-color: #c23a2b;
  .van-nav-bar__text {
    color: #fff;
  }
  .van-icon {
    color: #fff;
  }
  .van-nav-bar__title {
    color: #fff;
  }
}
/deep/ .van-nav-bar.styleC {
  background-color: #17161b;
  .van-nav-bar__text {
    color: #fff;
  }
  .van-icon {
    color: #fff;
  }
  .van-nav-bar__title {
    color: #fff;
  }
}
.main {
  height: calc(100vh - 46px);
  box-sizing: border-box;
  overflow-y: auto;
  .card {
    margin: 0 10px;
  }
  .card.styleB {
    margin-top: 10px;
  }
}
.main.styleC {
  background-color: #f6f6f6;
  .card.styleC {
    margin-top: 8px;
  }
}
</style>
